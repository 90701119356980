

function CommitteePage() {

    return(
        <div className={"committee-page"}>
            <div className={"committee-page__header"}>
                <h1 className={"committee-page__header__title"}>Our Committee Members</h1>
            </div>
            <div className={"committee-page__content"}>
                <div className={"committee-page__content__members"}>
                    <p>test</p>
                </div>
            </div>
        </div>
    );
}

export default CommitteePage;