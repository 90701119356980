import React from 'react';
import './Logo.scss'
import logo from '../../../assets/SemsuLogoTrimmed.webp'
import {ScrollIndicator} from "../../../components/ScrollIndicator/ScrollIndicator";


/**
 * Header component
 * @returns {JSX.Element}
 * @constructor
 */
const Logo = () => {
    const ref = React.useRef(null);
    // const [offset, setOffset] = React.useState(0);

    // React.useEffect(() => {
    //     let handleScroll;
    //     handleScroll = () => {
    //         setOffset(window.pageYOffset - ref.current.offsetTop +  450);
    //     }
    //     window.addEventListener("scroll", handleScroll);
    //
    //     return () => {
    //         window.removeEventListener("scroll", handleScroll);
    //     }
    // });


    return (
        <div className={"header-wrapper"}>
            {/*<AnimationOnScroll animateIn={"animate__fadeIn"} animateOut={"animate__fadeOutUp"} offset={250}  duration={2}>*/}
            <div ref={ref} style={{transform: `translateY(${0}px)` }} className={"Header"}>
                <img src={logo} alt={"logo"}/>
                <ScrollIndicator/>
            </div>
            {/*</AnimationOnScroll>*/}
        </div>
    );
}



export default Logo;
