import React from 'react';
import './CurrentEvent.scss';
// import {post_09_06_23 as poster} from '../../../assets/posters/lowQ';
import {getClosestEvent} from "../../../components/SanityHandler/SanityHandler";
import ProgressiveImage from "../../../components/ProgressiveImage/ProgressiveImage";

/**
 * displays the poster for the current event
 * @returns {JSX.Element}
 * @constructor
 */
const CurrentEvent = () => {
    //contains the events data
    const [eventData, setEventData] = React.useState(null);

    //gets the events data using the sanity handler
    React.useEffect(() => {
        async function getEvent() {
            let closestEvent = await getClosestEvent();
            setEventData(closestEvent);
            console.log(closestEvent);
        }
        getEvent().then();
    },[]);

    return (
            <>
                <div className={"currentEvent"}>
                    { eventData ?
                    <div className={"poster"}>
                        <ProgressiveImage low={eventData.imgUrlLow} high={eventData.imgUrlHigh} alt={"poster"}/>
                    </div>
                    : null}
                </div>
            </>
    );
}


export default CurrentEvent;