import React from "react";


export default function ParallaxBackground(){
    //offset value for background parallax
    const [offset, setOffset] = React.useState(0);

    //adding event listeners on page load
    React.useEffect(() => {
        //Event listener for scrolling for use with a parallax background
        const handleScroll = () => {
            const newOffset = window.pageYOffset - 650;
            // requestAnimationFrame(() => {
                setOffset(newOffset);
            // });
        };
        window.addEventListener("scroll", handleScroll);

       return(() => {
           window.removeEventListener("scroll", handleScroll)
       });
    },[offset])
    return(
        <>
            {/*initialises the separate background images for parallax*/}
            <div style={{transform: `translateY(${offset*0.01}px)`, transition: `all 0.1s`}} className={`background4 bgr`}/>
            <div style={{transform: `translateY(${offset*0.02}px)`, transition: `all 0.1s`}} className={`background3 bgr`}/>
            <div style={{transform: `translateY(${offset*0.03}px)`, transition: `all 0.1s`}} className={`background2 bgr`}/>
            <div className={"background bgr"}/>
        </>
    )
}