import imageUrlBuilder from '@sanity/image-url';
import { formatInTimeZone, getTimezoneOffset } from 'date-fns-tz';
import { addDays, addWeeks, addMonths, addYears } from 'date-fns';

const PROJECT_ID = "tg8p4gry";
const DATASET = "production";
const TYPE = "event";
const ORDER = "date.startDate desc";
const START = 0;
const END = 100;
const builder = imageUrlBuilder({ projectId: PROJECT_ID, dataset: DATASET });

export function buildSanityQuery() {
    return encodeURIComponent(`*[_type == "${TYPE}"] | order(${ORDER}) [${START}...${END}]`);
}

//ensure allowTestEvents is false when pushing
export function getSanityData(allowTestEvents = false) {
    if (localStorage.getItem("sanityData")) {
        console.log("Using locally cached data");
        const sanityData = JSON.parse(localStorage.getItem("sanityData"));
        if (sanityData && sanityData.data && sanityData.timestamp) {
            const now = new Date();
            const timeSinceLastFetch = now - new Date(sanityData.timestamp);
            const minutesSinceLastFetch = Math.floor(timeSinceLastFetch / 1000 / 60);

            if (minutesSinceLastFetch < 60) {
                return sanityData.data;
            }
        }
        console.log("Locally cached data is too old");
    }

    console.log("Fetching data from CMS");
    const QUERY = buildSanityQuery();
    const URL = `https://${PROJECT_ID}.api.sanity.io/v2021-10-21/data/query/${DATASET}?query=${QUERY}`;

    return fetch(URL)
        .then((res) => res.json())
        .then(({ result }) => {
            console.log(result);

            if (result.length > 0) {
                const now = new Date();
                const timezone = 'Europe/London'; // Adjust to your desired timezone

                const fetchedData = result.map((data) => {
                    const startDate = data.date?.startDate ? new Date(data.date.startDate) : null;
                    const endDate = data.date?.endDate ? new Date(data.date.endDate) : null;

                    console.log('Start date:', startDate);
                    console.log('End date:', endDate);

                    let allDates = [];
                    if (data.date?.rrule && startDate) {
                        allDates = generateRecurringDates(data.date.rrule, startDate, timezone);
                    }

                    return {
                        name: data?.title || 'No Title',
                        imgUrlLow: builder.quality(0).auto(`format`).width(100).image(data?.imgUrl?.asset?._ref).url(),
                        imgUrlHigh: builder.quality(80).auto(`format`).width(800).image(data?.imgUrl?.asset?._ref).url(),
                        ticketUrl: data?.ticketUrl || '',
                        summary: data?.summary || '',
                        description: data?.description || '',
                        genres: data?.genres || [],
                        location: data?.location || '',
                        locationUrl: data?.locationUrl || '',
                        recurringDates: allDates,
                        startDate: startDate,
                        endDate: endDate,
                        key: data._id || '',
                        testEvent: data?.testEvent || false
                    };
                });

                // Filter events based on allowTestEvents before saving to localStorage
                const filteredData = fetchedData.filter(event => {
                    return allowTestEvents || !event.testEvent;
                });

                localStorage.setItem("sanityData", JSON.stringify({ data: filteredData, timestamp: now }));

                return filteredData; // Return the filtered data
            }
        })
        .catch((err) => console.error(err));
}


function generateRecurringDates(rrule, startDate, timezone) {
    const ruleParts = rrule.split(';');
    const ruleMap = ruleParts.reduce((acc, part) => {
        const [key, value] = part.split('=');
        acc[key] = value;
        return acc;
    }, {});

    const initialOffset = getTimezoneOffset(timezone, startDate);
    let dates = [];

    let currentDate = new Date(startDate);

    while (dates.length < 10) {  // Limit to 10 occurrences for example
        const dateOffset = getTimezoneOffset(timezone, currentDate);
        const diff = initialOffset - dateOffset;
        currentDate.setTime(currentDate.getTime() + diff);
        dates.push(formatInTimeZone(currentDate, timezone, 'yyyy-MM-dd\'T\'HH:mm:ss.SSSxxx'));

        switch (ruleMap.FREQ) {
            case 'DAILY':
                currentDate = addDays(currentDate, 1);
                break;
            case 'WEEKLY':
                currentDate = addWeeks(currentDate, 1);
                break;
            case 'MONTHLY':
                currentDate = addMonths(currentDate, 1);
                break;
            case 'YEARLY':
                currentDate = addYears(currentDate, 1);
                break;
            default:
                break;
        }
    }
    console.log('Recurring dates:');
    console.log(dates);

    return dates;
}

export async function getClosestEvent() {
    const now = new Date();
    const fetchedProjects = await getSanityData();
    if (!fetchedProjects) {
        return;
    }

    const upcomingProjectsData = fetchedProjects.filter((project) => {
        if (project.recurringDates) {
            return project.recurringDates.some(date => new Date(date) >= now);
        }
        return project.startDate && new Date(project.startDate) >= now;
    });

    const pastProjectsData = fetchedProjects.filter((project) => {
        if (project.recurringDates) {
            return project.recurringDates.every(date => new Date(date) < now);
        }
        return project.startDate && new Date(project.startDate) < now;
    });

    if (upcomingProjectsData.length > 0) {
        upcomingProjectsData.sort((a, b) => {
            const dateA = new Date(a.startDate);
            const dateB = new Date(b.startDate);
            return dateA - dateB;
        });

        return upcomingProjectsData[0];
    }

    if (pastProjectsData.length > 0) {
        pastProjectsData.sort((a, b) => {
            const dateA = new Date(a.startDate);
            const dateB = new Date(b.startDate);
            return dateB - dateA;
        });
        return pastProjectsData[0];
    }

    return null;
}
