import React from 'react';
import './Collapsible.scss';

/**
 * Collapsible Context
 * @type {React.Context<{}>}
 */
const CollapsibleContext = React.createContext({});

/**
 * Collapsible Component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function Collapsible(props) {
    //Creates a value for storing the index of the selected collapsible
    const {activeCollapsible, setActiveCollapsible} = React.useContext(CollapsibleContext);

    //Toggles the collapsible
    const toggle = () => {
        if (activeCollapsible === props.header) {
            setActiveCollapsible('');
        } else {
            setActiveCollapsible(props.header);
        }
    }

    return (
        <div className={`collapsible`}>
            <div className={`collapsible-header ${activeCollapsible === props.header ? 'selected' : ''}`} onClick={toggle}>
                {props.header}
                <p>{activeCollapsible === props.header ? ("-") : ("+")}</p>
            </div>
                <div className={`collapsible-body ${((activeCollapsible === props.header) ? "collapse-visible" : "collapse-not-visible")}`}>
                    {props.body}
                </div>
        </div>
    );
}

/**
 * CollapsibleGroup Component
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function CollapsibleGroup(props) {
    //Creates a value for storing the index of the selected collapsible
    const [activeCollapsible, setActiveCollapsible] = React.useState('');

    return (
        <CollapsibleContext.Provider value={{activeCollapsible, setActiveCollapsible}} className={"CollapsibleGroup"}>
            {props.children}
        </CollapsibleContext.Provider>
    );
}