import './Footer.scss'
import React from "react";
import SemsuLogo from '../../assets/semsuBackgroundTrimmed.png';

export function Footer() {
    return (
        <div className={"Footer"}>
            <img src={SemsuLogo} alt={"Semsu Logo"}/>
            <div className={"FooterText"}>
                <p>©2023 SEMSU</p>
                <p>semsuevents@gmail.com</p>
            </div>
        </div>
    );
}
