import React, {useState, useCallback} from 'react';
import './EventScroller.scss';
import {AiFillCloseCircle, AiFillInfoCircle, AiOutlinePaperClip} from "react-icons/ai";
import { motion } from 'framer-motion'
import ProgressiveImage from "../../../components/ProgressiveImage/ProgressiveImage";

const EventScroller = (props) => {
    //sets up the state for the selected card
    const [selectedCardIndex, setSelectedCardIndex] = useState(null);

    //toggles the selected card based on the input index
    const toggleSelectedCard = useCallback((index) => {
        setSelectedCardIndex(selectedCardIndex === index ? null : index);
    }, [selectedCardIndex]);

    return (
        <>
            <div className={"titleText"}>{props.titleText}
               {/*<div className={"titleLine"}></div>*/}
            </div>

            <div className={"Main"}>
                <motion.div
                    transition={{duration: 0.5, delayChildren: 0.5}}
                    className={"eventScrollerMain " + props.class}
                >
                    {props.arrayOfEvents.map((work, index) => (
                        <div className={`app__work-item app__flex`} key={index}>

                            <div className={`card_cover app__flex ${selectedCardIndex === index ? "is-visible" : "not-visible"}`}>
                                <div className={"card_cover_contents"}>
                                    <h4 className={"bold-text"}>{work.title}</h4>
                                    <p className={"p-text"}>{work.description}</p>
                                    <p className={"bold-text"}>{work.genres}</p>
                                    <div className={"app__flex exit icon"} onClick={() => toggleSelectedCard(index)}><AiFillCloseCircle/></div>
                                </div>
                            </div>
                            <div className={`app__work-img__wrapper`}>
                                <div className={`app__work-img app__flex  ${selectedCardIndex === index ? "selected-card" : "unselected-card"}`}>
                                    <ProgressiveImage low={work.imgUrlLow} high={work.imgUrlHigh} alt={"poster"}/>

                                    <motion.div whileHover={{opacity: [0, 1]}} transition={{ duration: 0.25, ease: 'easeIn', staggerChildren: 0.5}} className={"app__work-hover"}>
                                        {work.url ?
                                        <a href={work.url}>
                                            <motion.div whileInView={{scale: [0,1]}} whileHover={{scale: [1,0.9]}} transition={{duration: 0.25}} className={"app__flex icon"}>
                                                <AiOutlinePaperClip/>
                                            </motion.div>
                                        </a> : null }
                                        <a href={"#body"} onClick={() => toggleSelectedCard(index)}>
                                            <motion.div whileInView={{scale: [0,1]}} whileHover={{scale: [1,0.9]}} transition={{duration: 0.25}} className={"app__flex icon"}>
                                                <AiFillInfoCircle/>
                                            </motion.div>
                                        </a>
                                    </motion.div>
                                </div>
                            </div>

                            <div className={`app__work-content app__flex  ${selectedCardIndex === index ? "selected-card" : "unselected-card"}`}>
                                <h4 className={"bold-text"}>{work.title}</h4>
                                <p className={`p-text descriptions`} style={{ marginTop: 10}}>{work.summary}</p>
                            </div>

                        </div>
                    ))}
                </motion.div>
            </div>
        </>
    );
}

export default React.memo(EventScroller);
