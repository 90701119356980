import React, {useState} from 'react';
import './NavBar.scss';
// import links from "../Links/Links";

/**
 * dropdown Menu component for the navbar
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */


/**
 * NavBar component
 * @returns {JSX.Element}
 * @constructor
 */


function Navbar(props){
    return(
        <nav className={"navbar"}>
            <div className={"Logo"}><a href={props.href}>{ props.logo }</a></div>
            <ul className={"navbar-nav"}>
                { props.children }
            </ul>
        </nav>

    )
}

function NavItem(props){
    const [open, setOpen] = useState(false);

    return(
        <li className={open ? "nav-item": "nav-item"}>
            {props.href ? <a href={props.href} className={"icon-button"} onClick={() => setOpen(!open)}>
                {props.icon}
            </a> : <div className={"icon-button"} onClick={() => setOpen(!open)}>{props.icon}</div>}
            <div className={open ? "dropdown" : "dropdown-invisible"}>
                    {props.children}
            </div>
        </li>
    )
}

function DropdownItem(props) {
    return (
        props.href ? <a href={props.href} className={`menu-item ${props.className ? props.className : ""}`}>{props.children}</a> : <div className={`menu-item ${props.className ? props.className : ""}`}>{props.children}</div>
    );
}


export {Navbar,NavItem,DropdownItem};