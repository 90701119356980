import React from 'react';
import './Footer.scss'

/**
 * Footer Component
 * @returns {JSX.Element}
 * @constructor
 */
const Footer = () => {
    return (
        <div></div>
    );
}

export default Footer;