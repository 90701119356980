import "./Links.scss";
import {AiOutlineInstagram, AiOutlineFacebook} from "react-icons/ai";
import {FaSoundcloud, FaSpotify, FaTiktok} from "react-icons/fa";
/**
 * Links component
 * @returns {JSX.Element}
 * @constructore
 */
const Links = () => {

    return(
        <div className={"Links"}>
            <a href={"https://www.facebook.com/s3msu/"}><AiOutlineFacebook></AiOutlineFacebook></a>
            <a href={"https://www.instagram.com/semsusoton/"}><AiOutlineInstagram></AiOutlineInstagram></a>
            <a href={"https://soundcloud.com/semsuevents"}><FaSoundcloud></FaSoundcloud></a>
            <a href={"https://www.tiktok.com/@semsu_events"}><FaTiktok></FaTiktok></a>
            <a href={"https://open.spotify.com/user/llzfkyeq770k5w80n3exe7qz3?si=8792a4b28df9434e"}><FaSpotify></FaSpotify></a>
        </div>
    )
}
//https://www.fatsoma.com/p/semsuevents
//semsuevents@gmail.com

export default Links;