import './ScrollIndicator.scss';
import React from 'react';
import { GoDotFill } from 'react-icons/go';
import { BsChevronDown } from 'react-icons/bs';

export function ScrollIndicator(props) {
    return(
        <div className={`scroll-indicator`}>
            <GoDotFill className={"scroll-dot dot-1"}/>
            <GoDotFill className={"scroll-dot dot-2"}/>
            <GoDotFill className={"scroll-dot dot-3"}/>
            <BsChevronDown className={"scroll-dot dot-4"}/>
        </div>
    )
}
