import React, {useEffect} from 'react';
import './ProgressiveImage.scss';

export default function ProgressiveImage({ low, high, ...props}) {
    const [src, setSrc] = React.useState(low || high);

    useEffect(() => {
        const img = new Image();
        img.src = high;
        img.onload = () => {
            setSrc(high);
        }
    });

    return (
        <img
            {...{src: src, ...props}}
            alt={props.alt || ""}
            className={"image"}
        />
    );
}