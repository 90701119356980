import './AboutPage.scss';
import React from "react";
import Collapsible, {CollapsibleGroup} from "../../components/collapsible/Collapsible";
import ParallaxBackground from "../../components/ParallaxBackground";

/**
 * The contents of the About Us page
 * @returns {JSX.Element}
 * @constructor
 */
function AboutPage() {
    return (
        <>
            <ParallaxBackground/>
            <div className={"about-page"}>
                <div  className={"CollapsibleGroup"}>
                    <CollapsibleGroup>
                        <Collapsible header={"Who are we?"}  body={"We are dedicated to promoting and encouraging a community of electronic music enthusiasts. Our aim is to bring together individuals interested in DJing, production, and the broader culture of electronic music. Additionally, we aim to promote safety in clubbing events and engage in discussions around the industry, making it more accessible to a diverse group of individuals. Through workshops, events, and collaborations, SEMSU aims to provide an environment for its members to learn, grow, and connect within the electronic music community.\n"}/>
                        <Collapsible header={"What does SEMSU stand for?"} body={"Society of Electronic Music Southampton University"}/>
                        <Collapsible header={"What do we do?"} body={"We host a variety of events around Southampton, and in the future we hope to start offering lessons for DJs of any skill level to improve themselves."}/>
                        <Collapsible header={"How do I join?"} body={"We have a variety of Social media through which we post updates about upcoming events, and you can message us through Instagram and Facebook or Email us at semsuevents@gmail.com if you wish to join SEMSU."}/>
                    </CollapsibleGroup>
                </div>
            </div>
        </>
    );
}

export default AboutPage;