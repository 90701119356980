import {CurrentEvent, EventDescription, Logo} from "../index";
import './HomePage.scss'
import React from "react";
import "animate.css/animate.min.css";
// import { AnimationOnScroll } from 'react-animation-on-scroll';
// import {FadeInSection} from "../../components/fade/Fade";
import ParallaxBackground from "../../components/ParallaxBackground";
// import AboutPage from "../AboutPage/AboutPage";
// import {TimeDisplay} from "../../components/countdown/Countdown";

/**
 * The contents of the Home page
 * @returns {JSX.Element}
 * @constructor
 */
function HomePage() {
    //width value for a reactive webapp
    const [width, setWidth] = React.useState(window.innerWidth);

    //breakpoint for decision between mobile and desktop versions
    const breakpoint = 768;

    //initialise event listeners on page load
    React.useEffect(() => {
                //Event listener for window resize for a reactive webapp
        const handleResize = () => {
            setWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);

        return(() => {
            window.removeEventListener("resize",handleResize)
        });
    }, []);

    return (
    <div>
        <ParallaxBackground/>
        <Logo/>
        {/* Main section of the homepage html with either a mobile or desktop version*/}
        {/*<TimeDisplay/>*/}
        {(width > breakpoint) ?
        <div className={"DesktopBody"}>
            <>
                <EventDescription mobile={false}/>
                <CurrentEvent mobile={false}/>

                {/*<div className={"LeftBox"}>*/}
                {/*    /!*<FadeInSection threshold={-500} positive={false} max={0}>*!/*/}
                {/*        */}
                {/*    /!*</FadeInSection>*!/*/}
                {/*</div>*/}
                {/*<div className={"RightBox"}>*/}
                {/*    /!*<FadeInSection threshold={500} positive={true} max={0}>*!/*/}
                {/*      */}
                {/*    /!*</FadeInSection>*!/*/}
                {/*</div>*/}
            </>
        </div> : <div className={"MobileBody"}>
                {/*<div className={"title-bar"}>*/}
                {/*    <h1>Upcoming Event</h1>*/}
                {/*    <p> v </p>*/}
                {/*</div>*/}
            <CurrentEvent mobile={true}/>
            <EventDescription mobile={true}/>
            {/*<AboutPage/>*/}
        </div>}


    </div>
    );
}

export default HomePage;