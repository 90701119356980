import './App.scss';
import {Links} from "./container";
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from 'react-router-dom';
import SemsuLogoTrimmed from "./assets/SemsuLogoTrimmed.webp";
import React from "react";
import {Navbar, NavItem, DropdownItem, Footer} from "./components";
import {AiOutlineFacebook, AiOutlineInstagram, AiOutlineMenuFold} from "react-icons/ai";
import HomePage from "./container/HomePage/HomePage";
import {FaSoundcloud, FaSpotify, FaTiktok} from "react-icons/fa";
import AboutPage from "./container/AboutPage/AboutPage"
import EventsPage from "./container/EventsPage/EventsPage";
import CommitteePage from "./container/CommitteePage/CommitteePage";

function App() {
    const home = window.location.pathname === "/";
    const events = window.location.pathname === "/Events";
    const about = window.location.pathname === "/About";

    const [width, setWidth] = React.useState(window.innerWidth);
    const breakpoint = 620;

    React.useEffect(() => {
        window.addEventListener("resize", () => setWidth(window.innerWidth));
        return() => window.removeEventListener("resize", () => setWidth(window.innerWidth));
    }, []);

    return (
      <body>
          <Router>
              <Navbar logo={(width > breakpoint) &&
                  <>
                    <a href={"/"} className={home && "selected"}>Home</a>
                    <a href={"/Events"} className={events && "selected"}>Events</a>
                    <a href={"/About"} className={about && "selected"}>About</a>
                  </>
              }>

                  { (width > breakpoint) ? <>
                  <NavItem href={"https://www.facebook.com/s3msu/"} icon={<AiOutlineFacebook/>}/>
                  <NavItem href={"https://www.instagram.com/semsusoton/"} icon={<AiOutlineInstagram/>}/>
                  <NavItem href={"https://soundcloud.com/semsuevents"} icon={<FaSoundcloud/>}/>
                  <NavItem href={"https://www.tiktok.com/@semsu_events"} icon={<FaTiktok/>}/>
                  <NavItem href={"https://open.spotify.com/user/llzfkyeq770k5w80n3exe7qz3?si=8792a4b28df9434e"} icon={<FaSpotify/>}/> </>
                  : <NavItem icon={<AiOutlineMenuFold/>}>
                          <DropdownItem ><img src={SemsuLogoTrimmed} alt={"Semsu Logo"}/></DropdownItem>
                          <DropdownItem className={home && "selected"} href={"/"}><h1>Home</h1></DropdownItem>
                          <DropdownItem className={events && "selected"} href={"/events"}><h1>Events</h1></DropdownItem>
                          <DropdownItem className={about && "selected"} href={"/about"}><h1>About Us</h1></DropdownItem>
                          <DropdownItem>
                              <Links/>
                          </DropdownItem>
                    </NavItem> }
                  </Navbar>
              <Routes>
                  <Route path={"/"} element={
                      <HomePage/>
                  }/>
                  <Route path={"/events"} element={
                    <EventsPage/>
                  }/>
                  <Route path={"/about"} element={<AboutPage/>}/>
                  <Route path={"/committee"} element={<CommitteePage/>}/>
              </Routes>
              <Footer/>
          </Router>
      </body>

  );
}



//semsuevents@gmail.com
export default App;
